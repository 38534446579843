<template>
<ODialog ref="modal" @show="() => isShown = true" @hidden="() => isShown = false" size="xl"
    :title="layoutName">
    <div class="o365-dialog-body">
        <div class="overflow-auto" style="max-height: 80vh;">
            <JSONEditor v-model="layoutJSON" readonly/>
        </div>
    </div>
</ODialog>
</template>

<script setup lang="ts">
import type { LayoutObject } from 'o365-dataobject';
import { ODialog, OJSONEditor as JSONEditor } from 'o365-ui-components';
import { ref, computed } from 'vue';

const props = defineProps<{
    layoutObject: LayoutObject,
    editable?: boolean
}>();

const modal = ref<ODialog|null>(null);

const isShown = ref(false);

const layoutName = computed(() => {
    if (props.layoutObject.name) {
        return props.layoutObject.name;
    } else {
        return 'LAYOUT_PLACEHOLDER';
    }
});

const layoutJSON = computed(() => {
    return props.layoutObject?.layoutJSON;
});

const layoutModules = computed(() => {
    if (!isShown.value) {
        return [];
    } else {
        return props.layoutObject.modulesArray.map(layoutModule => ({
            value: layoutModule.getValue(),
            displayName: layoutModule.getDisplayName(),
            clear: () => layoutModule.reset(),
        }));
    }
});

function open() {
    modal.value?.showDialog();
}

defineExpose({open});
</script>